import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import getHoverQuery from "styles/getHoverQuery";
import listItemStyles from "styles/ListItemStyles";
import ScreenSizes from "styles/ScreenSizes";

export const thumbnailSize = 104;

const borderStyle = {
  borderTop: {
    borderTop: "1px solid #e9f0f9",
  },
  borderRight: {
    borderRight: "1px solid #e9f0f9",
  },
  borderBottom: {
    borderBottom: "1px solid #e9f0f9",
  },
  borderLeft: {
    borderLeft: "1px solid #e9f0f9",
  },
}; // rgba(0,30,60,0.07)'; // #f7f8f9'; //rgba(0,0,0,0.15)';

// PC:TRANSLATE
const mainStyles = {
  ...listItemStyles,
  containerWithoutShadow: {
    // TODO: Not sure why this exists. - Ryan
    marginBottom: 10,
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  fillContainer: {
    display: "flex",
    flex: 1,
  },
  container: {
    boxShadow: "0px 7px 17px 0 rgba(0, 0, 0, 0.08)",
    width: "100%",
    backgroundColor: "white",
  },
  row: {
    flexDirection: "column",
    display: "flex",
    height: "100%",
    width: "100%",
    background: "inherit",

    [ScreenSizes.mdAndAbove]: {
      flexDirection: "row",
    },
  },
  info: {
    position: "relative",
    flexDirection: "row",
    overflow: "hidden",
    display: "flex",
    flex: "1 1 auto",
    background: "inherit",

    [ScreenSizes.lgAndAbove]: {
      width: "auto",
    },
  },
  subtitleLink: {
    ...getHoverQuery({
      color: colours.oldSecondary,
    }),
  },
  meta: {
    height: "auto",
    lineHeight: "1.6em",
    ...gStyles.fontRegular,
    margin: 0,
    marginBottom: "0.3em",
    padding: 0,
    listStyleType: "none",
    fontStyle: "italic",
    fontSize: 14,
    color: colours.greyishBlue,
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    position: "relative",
    left: -2,
    flexDirection: "row",
    flexWrap: "wrap",
  },
  metaItem: {
    height: "auto",
    display: "inline-block",
    margin: "0",
    padding: "0 1em 0 0",
    overflow: "visible",
    lineHeight: "1.3",
    position: "relative",

    ":last-child": {
      paddingRight: "3px",
      borderRight: "0",
    },
    [ScreenSizes.mdAndAbove]: {
      overflow: "hidden",
    },
  },
  metaContent: {
    flexBasis: "20%",
    width: "20%",
    flexGrow: 1,
    flexShrink: 1,
    flexDirection: "column",
    paddingLeft: 8,
    paddingRight: 8,
    borderRight: "none",
    overflow: "hidden",
    paddingTop: 8,

    [ScreenSizes.smAndAbove]: {
      flexBasis: "100%",
      width: "auto",
    },
    [ScreenSizes.lgAndAbove]: {
      padding: "0.9rem 1.25rem 0 1rem",
      alignItems: "flex-start",
    },
  },
  metaLink: {
    color: colours.grey,
    textDecoration: "none",

    ...getHoverQuery({
      textDecoration: "underline",
    }),

    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  metaLinkTitle: {
    ...gStyles.fontRegular,
    color: colours.black,
    textDecoration: "none",

    ...getHoverQuery({
      textDecoration: "underline",
    }),
  },
  metaItemIcon: {
    marginRight: "0.3em",
  },
  userContent: {
    flexWrap: "initial",
    minHeight: 53,
    flex: "0 0 53px",
    flexDirection: "row",
    background: "inherit",
    padding: "0.5em 0.75em",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",

    [ScreenSizes.mdAndAbove]: {
      flexWrap: "wrap",
      flexDirection: "column",
      minHeight: 60,
      padding: "0.8em 0.75em .5em",
      justifyContent: "flex-start",
    },
  },
  userContentBtnWrapper: {
    marginBottom: 6,
    marginLeft: 4,
    minWidth: 49,
    maxWidth: 60,
    justifyContent: "flex-end",
    position: "relative",
    minHeight: 39,
    width: "32%",

    [ScreenSizes.mdAndAbove]: {
      marginBottom: 5,
      marginLeft: "auto",
    },
  },
  userContentBtnFirst: {
    flexGrow: 0,
    marginLeft: 0,
    width: "auto",
    flexShrink: 0,
    maxWidth: 80,
    justifySelf: "flex-start",
    marginRight: "auto",
    marginBottom: 4,
    flexBasis: "100%",

    [ScreenSizes.mdAndAbove]: {
      flexBasis: "auto",
      marginLeft: 0,
      width: "100%",
      flexShrink: 1,
    },
  },
  userContentBtnLast: {
    marginBottom: 0,
  },
  ratings: {
    ...borderStyle.borderRight,
    background: "inherit",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    lineHeight: "1",
    height: "100%",
    flex: "0 0 50%",
    borderBottom: "none",
    borderRight: "none",
  },
  actions: {
    ...borderStyle.borderRight,
    width: "50%",
    height: "100%",
    flex: "0 0 50%",
    background: "inherit",
    position: "relative",
  },
  description: {
    ...gStyles.fontLight,
    padding: 0,
    margin: 0,
    lineHeight: "1.5",
    overflow: "hidden",
    letterSpacing: "0.02em",
    wordBreak: "break-word",
    maxHeight: "none",
    color: "var(--color-neutral-d3)",
    fontSize: "0.75em",
    marginBottom: "0.2em",
  },
  upperPart: {
    display: "flex",

    [ScreenSizes.mdAndAbove]: {
      display: "block",
    },
  },
  titleAndStarsWrapper: {
    width: "100%",
    paddingTop: "6px",
  },
  title: {
    ...gStyles.fontBold,
    color: "var(--color-neutral-d4)",
    margin: 0,
    lineHeight: "1.3em",
    position: "relative",
    fontSize: "0.875rem",

    [ScreenSizes.lgAndAbove]: {
      fontSize: "1.125rem",
      lineHeight: "1.2em",
    },
  },
  subtitle: {
    display: "flex",
    alignItems: "center",
    fontSize: "0.8em",
  },
  subtitleItem: {
    marginRight: "0.8em",
    color: colours.cardSubtitleText,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",

    [ScreenSizes.mdAndBelow]: {
      marginTop: 5,
    },
  },
  subtitleRating: {
    width: "auto",
  },
  subtitleText: {
    ...gStyles.fontSemiBold,
    fontSize: "0.625rem",
    textTransform: "capitalize",
  },
  subtitleIcon: {
    fontSize: 14,
    marginRight: "0.3em",
  },
  ratingsWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "auto",
    alignItems: "flex-start",
    position: "relative",
    left: "-2px",
    marginTop: 0,
  },
  contextTitle: {
    ...gStyles.fontSemiBold,
    fontSize: 11,
    marginBottom: "0.3em",
    color: colours.oldSecondary,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  containerNotShadowed: {
    boxShadow: "none",
    borderRight: "none",
  },
  rightFade: {},
  titleLink: {
    textDecoration: "none",
    color: "#1e376d",
  },
  asideBlock: {
    backgroundColor: "yellow",
  },
  playBtnLabel: {
    display: "flex",
    flexDirection: "column",
  },
  playBtnText: {
    fontSize: "0.8em",
    margin: 0,
  },
  starContainer: {
    flex: "0 0 auto",
    padding: "0.3em 0",
  },
  ratingLabel: {
    ...gStyles.fontRegular,
    margin: "0",
    padding: "0 0 0 0",
    fontSize: "0.5rem",
    color: "#b5b9c2",
    textAlign: "center",
    textTransform: "uppercase",
    letterSpacing: "0.1em",
    lineHeight: "1",
  },
  ratingLabelStatic: {
    color: "var(--color-neutral-d3)",
    margin: 0,
    fontStyle: "normal",
    fontWeight: 600,
    textTransform: "capitalize",
  },
  ratingSubtitle: {
    ...gStyles.fontRegular,
    margin: "0",
    padding: "6px 0 0 0",
    fontSize: "0.5rem",
    color: "#b5b9c2",
    textAlign: "center",
    textTransform: "uppercase",
    letterSpacing: "0.1em",
    lineHeight: "1",
  },
  categoriesWrapper: {
    display: "flex",
    justifyContent: "flex-start",
    marginTop: "0.5em",
    position: "relative",
    left: "-2px",
    flexDirection: "row",
    marginBottom: 8,
  },
  categoryLabel: {
    fontStyle: "normal",
    fontSize: "0.625em",
    fontWeight: 600,
    margin: 0,
    marginRight: "5px",
    borderRadius: "7px",
    backgroundColor: "#edeff4",
    color: "var(--color-neutral-d6)",
    textTransform: "capitalize",
    textAlign: "center",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    padding: "0.2em 0.7em",
    marginBottom: 0,

    ...getHoverQuery({
      backgroundColor: "#e3e5ea",
      color: "#7d889f",
    }),
  },
  asideTypeWrapper: {
    textAlign: "right",
    paddingRight: "1em",
  },
  asideTypeIcon: {
    color: "white",
    backgroundColor: "#b0b7c6",
    width: "20px",
    height: "20px",
    textAlign: "center",
    borderRadius: "50%",
    paddingTop: "1px",
  },
  asideTypeText: {
    textTransform: "uppercase",
    color: "#b0b7c6",
    fontWeight: 600,
    fontSize: "0.9em",
  },
  podcastLink: {
    textTransform: "capitalize",
    backgroundColor: colours.primary,
    marginRight: "1em",
    marginBottom: "1em",
    padding: "0.5em 1em",
    color: "white",
    fontWeight: 600,
  },
  rateAction: {
    background: "inherit",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    width: "50%",
    height: "100%",
  },
  saveAction: {
    background: "inherit",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    width: "50%",
    height: "100%",
    left: "50%",
  },
  menuButton: {
    position: "absolute",
    top: "15px",
    right: "15px",
    width: 30,
    cursor: "pointer",
  },
  star: {
    marginLeft: "0.05em",
    marginRight: "0.05em",
  },
  starEmpty: {
    color: colours.stars.empty,
  },
  starFilled: {
    color: colours.stars.filled,
  },
  startEstimatedRating: {
    color: colours.lightishGrey,
  },
  ratingSubtitleLink: {
    ...getHoverQuery({
      textDecoration: "none",
      color: colours.black,
    }),
  },
  rating: {
    [ScreenSizes.mdAndAbove]: {
      fontSize: "1.3em",
    },
  },
  searchHighlightTitle: {
    // This doesn't really work yet, but it might not matter for now,
    // it's clear when it's in the title.
    ...gStyles.fontBold,
    color: colours.oldSecondary,
  },
  searchHighlightDescription: {
    ...gStyles.fontBold,
    color: colours.oldSecondary,
  },
  lowerPart: {
    paddingLeft: "1em",
  },
  thumbnail: {
    height: 60,
    position: "relative",
    boxSizing: "border-box",
    flex: "0 0 46px",
    backgroundColor: "white",
    margin: 8,

    [ScreenSizes.smAndAbove]: {
      flex: "0 0 61px",
    },
    [ScreenSizes.mdAndAbove]: {
      margin: "0.8rem 11px 11px 0.75rem",
    },
    [ScreenSizes.lgAndAbove]: {
      margin: 0,
      flex: "0 0 160px",
      height: "auto",
      padding: "6px",
    },
  },

  // force small for the forceComponentSize prop
  forceSmallRow: {
    [ScreenSizes.mdAndAbove]: {
      flexDirection: "column",
    },
  },
  forceSmallMetaItem: {
    [ScreenSizes.mdAndAbove]: {
      overflow: "visible",
    },
  },
  forceSmallMetaContent: {
    [ScreenSizes.lgAndAbove]: {
      flexBasis: "100%",
      width: "auto",
      padding: 8,
      paddingBottom: 0,
    },
  },
  forceSmallUserContent: {
    [ScreenSizes.mdAndAbove]: {
      flexWrap: "initial",
      flexDirection: "row",
      minHeight: 53,
      padding: "0.5em 0.75em",
      justifyContent: "space-between",
    },
  },
  forceSmallUserContentBtnWrapper: {
    [ScreenSizes.mdAndAbove]: {
      marginBottom: 6,
      marginLeft: 4,
    },
  },
  forceSmallUserContentBtnFirst: {
    [ScreenSizes.mdAndAbove]: {
      flexBasis: "100%",
      marginLeft: 0,
      width: "auto",
      flexShrink: 0,
    },
  },
  forceSmallUpperPart: {
    [ScreenSizes.mdAndAbove]: {
      display: "flex",
    },
  },
  forceSmallTitle: {
    [ScreenSizes.lgAndAbove]: {
      fontSize: "0.875rem",
      lineHeight: "1.3em",
    },
  },
  forceSmallSubtitleItem: {
    [ScreenSizes.mdAndBelow]: {
      marginTop: 0,
    },
  },
  forceSmallRating: {
    [ScreenSizes.mdAndAbove]: {
      fontSize: "iniial",
    },
  },
  forceSmallThumbnail: {
    [ScreenSizes.mdAndAbove]: {
      margin: 8,
    },
    [ScreenSizes.lgAndAbove]: {
      margin: 8,
      flex: "0 0 61px",
      height: 60,
      padding: 0,
    },
  },
};

const horizontalImageSize = 60;
// PC:TRANSLATE
const horizontalStyles = {
  userContent: {
    flex: "0 0 300px",
    flexDirection: "row",
  },
  ratings: {
    border: "none",
  },
  thumbnail: {
    flex: `0 0 ${horizontalImageSize + 1}px`,
    height: horizontalImageSize,
  },
};

const smallStyles = {};
// PC:TRANSLATE
const lessPaddingStyles = {
  thumbnail: {
    borderRight: 0,
    padding: 0,
  },
  thumbnailLink: {
    border: "none",
    boxShadow: "none",
  },
};

export { mainStyles, horizontalStyles, smallStyles, lessPaddingStyles };
