import { Map } from "immutable";
import PropTypes from "prop-types";
import { memo, useCallback } from "react";

import EllipsisMenuItem from "./EllipsisMenuItem";

import authActions from "actions/auth";
import modalActions from "actions/modals";
import { getCreatorEditCreditsOverview } from "utils/url/creatorUrls";

import useActionCreators from "hooks/useActionCreators";
import { useLoggedIn } from "hooks/useLoggedInUser";

const EllipsisMenuAddCreditsItem = (props) => {
  const { toggleMenu, entity, entity_type } = props;

  const isLoggedIn = useLoggedIn();

  const { startAuthFlow, showModal } = useActionCreators({
    startAuthFlow: authActions.startAuthFlow,
    showModal: modalActions.showModal,
  });

  const handleAddCreditsClick = useCallback(() => {
    if (isLoggedIn) {
      showModal("prompt", {
        promptType: entity_type === "podcast" ? "creator" : "episodeCreator",
        entity,
        entity_type,
        title: `Add creators to ${
          entity_type === "episode" ? `the ${entity_type} - ` : ""
        }"${entity.get("title")}"`,
        page: "Add Creator Page",
        introText: `Could you improve the credits for this ${entity_type}? Search below to find them or add them as a new creator.`,
      });
    } else {
      startAuthFlow(undefined, {
        entity,
        entity_type,
        actionType: "Add more appearances to",
        entityAction: "action",
        analyticsVariables: {
          triggered_by: "ellipsisMenuAddCreditsClick",
        },
      });
    }

    toggleMenu();
  }, [isLoggedIn, showModal, startAuthFlow, entity, entity_type, toggleMenu]);

  if (entity_type === "creator") {
    return (
      <EllipsisMenuItem
        label="Add Credits"
        href={getCreatorEditCreditsOverview(entity)}
        target="_blank"
        anchor
        {...props}
      />
    );
  }

  return (
    <EllipsisMenuItem
      label="Add Creators"
      onClick={handleAddCreditsClick}
      {...props}
    />
  );
};

EllipsisMenuAddCreditsItem.propTypes = {
  toggleMenu: PropTypes.func.isRequired,
  entity: PropTypes.instanceOf(Map).isRequired,
  entity_type: PropTypes.string.isRequired,
};

export default memo(EllipsisMenuAddCreditsItem);
